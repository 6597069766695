.pulse {
  position: relative;
  border-radius: 48px !important;
  padding: 12px 32px !important;
  width: max-content;
  text-transform: none !important;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;

  &Animate {
    &:after,
    &:before {
      content: '';
      width: 106px;
      height: 46px;
      position: absolute;
      border: 1px solid #323131;
      left: 2.5px;
      top: 1.2px;
      border-radius: 48px;
      animation: pulse 5s linear infinite;
      opacity: 0;
    }
    &:after {
      animation-delay: 2.5s;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.iconAnimate {
  animation: hide 3s ease-in-out ;
  cursor: pointer;
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}