.mini_loader {
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: -360deg;
    }
}