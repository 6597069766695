@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 5px;
  /* ширина скроллбара */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* цвет фона скроллбара */
}

::-webkit-scrollbar-thumb {
  background: #000;
  /* цвет ползунка */
  border-radius: 10px;
  /* закругленные углы */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* цвет ползунка при наведении */
}

* {
  box-sizing: border-box;
}