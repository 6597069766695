.switch {
  margin: 0;
  max-height: 24px;
  width: 24px;
  display: inline;
  position: relative;
}

input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0;
  max-height: 16px;
  width: 24px;
}

input[type=checkbox]:checked,
input[type=checkbox]:focus {
  outline: none !important;
}

input[type=checkbox]+.lbl,

input[type=checkbox]+.lbl::before {
  background-image: url("../../../public/assets/icons/empty_box.svg");
  background-position: center;

  display: inline-block;
  height: 24px;
  width: 24px;
}

input[type=checkbox]:checked+.lbl::before {
  display: inline-block;
  content: url('../../../public/assets/icons/check.svg');
  background-image: none;
  margin-left: 8px;
  margin-top: 0;
}