.bg {
  width: 896px;
  height: 896px;
  object-fit: contain;
  overflow: hidden;
  filter: blur(26px);
  opacity: 0.4;

  @media (max-height: 900px) {
    width: 800px;
    height: 800px;
  }
  @media (max-width: 624px) {
    height: 100vh;
  }
}

.rotate {
  animation: rotate 96s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}